<template>
        <el-dialog title="Servicii" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
            <el-col :md='8'>
                <el-form-item label='Nume' prop="Nume" >
                    <el-input  class='full-width' v-model='selectedObject.Nume' />
                </el-form-item>
            </el-col>
            <el-col :md='8'>
                <el-form-item label='Observatii' >
                    <el-input  class='full-width' v-model='selectedObject.Observatii' />
                </el-form-item>
            </el-col>            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Servicii_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObject: { Nume: '' , Observatii: '' },                   
                Info:{ },
                                   
                rules: {
                    Nume: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Observatii: [ { required: false, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("servicii/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("servicii/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>